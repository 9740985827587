import { ToastContainer } from "react-toastify";
import Header from "./Components/Header";
import FormBox from "./Components/FormBox";
import Footer from "./Components/Footer";
const App=()=>{
  return(<>
  <Header/>
  <FormBox/>
  <Footer/>
  <ToastContainer />
  </>)
}
export default App;