import React, { useEffect, useState } from 'react';
import axios from '../services/axios';
import showToastMessage from '../Common/Toastify';
import Spinner from './Spinner';
import CheckObjEmpty from '../Common/CheckObjEmpty';

const FormBox = () => {
    const initalValues = { fName: "", lName: "", phone: "", email: "", subject: "", message: "" };
    const [formValues, setFormValues] = useState(initalValues);
    const [inputErrors, setInputErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isValidation, setIsValidation] = useState(false);

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
        setInputErrors(validate(formValues));
    }

    const submitHandleForm = async (e) => {
        e.preventDefault();
        let validationStatus =  new Promise((resolve, reject)=>{
            const checkTrue=validate(formValues)
            setIsValidation(CheckObjEmpty(checkTrue))
            setInputErrors(checkTrue);
            resolve(checkTrue);
        })
        const checkError=await validationStatus.then(res=>res)
        //console.log('checkError checkError checkError', checkError)
    }

    useEffect(() => {
        isValidation && submitInquiryForm();
    }, [isValidation]);

    const submitInquiryForm = async () => {
        //console.log('isValidation isValidation', isValidation)
		const postData = formValues;
        if (postData.fName && isValidation) {
            setIsLoading(true);
           await axios.post('lead-inquiry', postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
          }).then((res) => {
                //console.log('res asdasdas', res)
                setIsLoading(false);
                if(res?.data?.returnCode === '1'){
                    setFormValues(initalValues);
                    showToastMessage(res?.data?.message, "success");
                    setIsValidation(false);
                } else {
                    showToastMessage(res?.data?.message, "error");
                    setIsValidation(false);
                }
           }).catch((error) => {
                console.log('error', error)
                showToastMessage(error, "error");
                setIsLoading(false);
                setIsValidation(false);
           });
        } else {
            console.log('Else')
            setIsLoading(false);
            setIsValidation(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const numberRegex = /^[1-9]\d{9}$/;

        if (!values.fName) {
            errors.fName = "First Name Is Required";
        } else if (values.fName.length < 3) {
            errors.fName = "Please Enter at least 3 Character";
        }
        if (!values.lName) {
            errors.lName = "Last Name Is Required";
        } else if (values.lName.length < 3) {
            errors.lName = "Please Enter at least 3 Character";
        }
        if (!values.phone) {
            errors.phone = "Phone Number Is Required";
        } else if (!numberRegex.test(values.phone)) {
            errors.phone = "Please Enter Only Numeric Value";
        }else if(values.phone.length < 10){
            errors.phone = "Please Enter 10 Digits";
        }
        if (!values.email) {
            errors.email = "Email Address Is Required";
        } else if (!emailRegex.test(values.email)) {
            errors.email = "Please Enter Valid Email Address";
        }
        if (!values.subject) {
            errors.subject = "Subject Is Required";
        }
        if (!values.message) {
            errors.message = "Message Is Required";
        }
        return errors;
    }
    return (<>
    {isLoading && <Spinner/>}
        <section className="form-banner bg-banner" style={{ paddingTop: "130px" }}>
            <div className="container">
                <div className="box-content text-center text-white mb-5">
                    <h2 className="fw-bold text-uppercase">Coming Soon</h2>
                    <p>Please fill out this form for any questions or additional information, and we'll get back to you as soon as possible.</p>
                </div>
                <div className="form-inquiry-box py-5">
                    <form onSubmit={submitHandleForm}>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-5 text-white position-relative">
                                    <label htmlFor="">First Name</label>
                                    <input type="text" className="form-control" placeholder="Enter First Name"
                                        name="fName" value={formValues.fName} onChange={handleChangeInput} />
                                    <span className="error">{inputErrors.fName}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-5 text-white position-relative">
                                    <label htmlFor="">Last Name</label>
                                    <input type="text" className="form-control" placeholder="Enter Last Name"
                                        name="lName" value={formValues.lName} onChange={handleChangeInput} />
                                    <span className="error">{inputErrors.lName}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-5 text-white position-relative">
                                    <label htmlFor="">Phone Number</label>
                                    <input type="text" className="form-control" placeholder="Enter Phone Number"
                                        name="phone" value={formValues.phone} onChange={handleChangeInput} />
                                    <span className="error">{inputErrors.phone}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-5 text-white position-relative">
                                    <label htmlFor="">Email Address</label>
                                    <input type="email" className="form-control" placeholder="Enter Email Address"
                                        name="email" value={formValues.email} onChange={handleChangeInput} />
                                    <span className="error">{inputErrors.email}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-5 text-white position-relative">
                                    <label htmlFor="">Subject</label>
                                    <input type="text" className="form-control" placeholder="Enter Subject"
                                        name="subject" value={formValues.subject} onChange={handleChangeInput} />
                                    <span className="error">{inputErrors.subject}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-5 text-white position-relative">
                                    <label htmlFor="">Message</label>
                                    <input type="text" className="form-control" placeholder="Enter Message"
                                        name="message" value={formValues.message} onChange={handleChangeInput} />
                                    <span className="error">{inputErrors.message}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 text-center">
                                    <button className="btn btn-light fw-bold" style={{ fontSize: "0.85rem" }}>Notify Me</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </>)
}
export default FormBox;