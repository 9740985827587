import { useEffect } from "react";
const Header = () => {
    const isSticky = (e) => {
        const header = document.querySelector('.polynomial-navbar');
        const scrollTop = window.scrollY;
        scrollTop >= 200 ? header.classList.add('d-none') : header.classList.remove('d-none');
    };
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    return (<>
        <nav className="navbar polynomial-navbar navbar-expand-lg fixed-top">
            <div className="container border-bottom pb-2">
                <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="fa-solid fa-bars"></span>
                </button>
                <a className="navbar-brand" href="/">
                    <img src="assets/img/white-logo.png" style={{width:"160px"}} alt="logo" />
                </a>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">Vision</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">Mission</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">Team/Trainer</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>)
}
export default Header;