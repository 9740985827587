const Footer=()=>{
    return(<>
    <footer className="footer-section pt-5 pb-3" style={{backgroundColor:"#000"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <ul className="widget-menu nav flex-column">
                        <li className="nav-item"><a className="nav-link p-0" href="mailto:care@highpolynomial.com">care@higherpolynomial.com</a></li>
                        <li className="nav-item"><a className="nav-link p-0" href="tel:+917498703442">+91-7498703442</a></li>
                        <li className="nav-item"><a className="nav-link p-0" href="/">B-50, Saraswati Kunj, 25 - I.P Extension, Delhi-92</a></li>
                    </ul>
                </div>
                <div className="col-md-2">
                    <ul className="widget-menu nav flex-column">
                        <li className="nav-item"><a className="nav-link p-0" href="/">Course</a></li>
                        <li className="nav-item"><a className="nav-link p-0" href="/">Blog</a></li>
                        <li className="nav-item"><a className="nav-link p-0" href="/">Contact Us</a></li>
                        <li className="nav-item"><a className="nav-link p-0" href="/">Faq</a></li>
                    </ul>
                </div>
                <div className="col-md-2">
                    <ul className="widget-menu nav flex-column">
                        <li className="nav-item"><a className="nav-link p-0" href="/">Vision</a></li>
                        <li className="nav-item"><a className="nav-link p-0" href="/">Mission</a></li>
                        <li className="nav-item"><a className="nav-link p-0" href="/">Team/Trainers</a></li>
                        <li className="nav-item"><a className="nav-link p-0" href="https://www.dev.higherpolynomial.com/refund-policy">Refund Policy</a></li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <p className="text-white fw-bold">Social Profiles</p>
                    <div className="widget-icons d-flex">
                        <a className="nav-link" href="https://www.facebook.com/profile.php?id=61561053150664" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook"></i></a>
                        <a className="nav-link" href="https://x.com/higherpoly" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                        <a className="nav-link" href="https://www.linkedin.com/company/higher-polynomial/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a>
                        <a className="nav-link" href="https://www.instagram.com/higherpolynomial/" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                    </div>
                </div>
            </div>
            <hr className="text-white"/>
            <div className="footer-copyright row">
                <div className="col-md-6">
                    <p className="text-white mb-0 fw-bold" style={{fontSize:"0.85rem"}}>&copy; {(new Date().getFullYear())} Higher Ploynomial. All Rights Reserved</p>
                </div>
                <div className="col-md-6 privacy-box">
                    <a className="text-white fw-bold text-decoration-none pe-2" style={{fontSize:"0.85rem"}} href="https://www.dev.higherpolynomial.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
                    <a className="text-white fw-bold text-decoration-none" style={{fontSize:"0.85rem"}} href="https://www.dev.higherpolynomial.com/terms-and-conditions" target="_blank" rel="noreferrer">Terms And Conditions</a>
                </div>
            </div>            
        </div>
    </footer>
    </>)
}
export default Footer;